@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      .h-m-content {
          height: 'max-content';
      }

      .h-192 {
          height: 48rem;
      }
    }
  }
  